/*Loader Css*/

.lds-ellipsis {
    margin: 0 auto;
    position: relative;
    width: 64px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 9999;
}
.lds-ellipsis span {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #FF324D;
    -webkit-animation: ball-pulse-sync 1s 0s infinite ease-in-out;
    animation: ball-pulse-sync 1s 0s infinite ease-in-out;
}
.lds-ellipsis span:nth-child(1) {
    -webkit-animation:ball-pulse-sync 1s -.14s infinite ease-in-out;
    animation:ball-pulse-sync 1s -.14s infinite ease-in-out
}
.lds-ellipsis span:nth-child(2) {
    -webkit-animation:ball-pulse-sync 1s -70ms infinite ease-in-out;
    animation:ball-pulse-sync 1s -70ms infinite ease-in-out
}
@-webkit-keyframes ball-pulse-sync {
    33% {
        -webkit-transform:translateY(10px);
        transform:translateY(10px)
    }
    66% {
        -webkit-transform:translateY(-10px);
        transform:translateY(-10px)
    }
    100% {
        -webkit-transform:translateY(0);
        transform:translateY(0)
    }
}
@keyframes ball-pulse-sync {
    33% {
        -webkit-transform:translateY(10px);
        transform:translateY(10px)
    }
    66% {
        -webkit-transform:translateY(-10px);
        transform:translateY(-10px)
    }
    100% {
        -webkit-transform:translateY(0);
        transform:translateY(0)
    }
}